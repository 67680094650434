import React  from "react"
import { Link } from "gatsby"
import Image from "../components/image"
import Layout from "../components/layout"
// import SEO from "../components/seo"
import "../assets/sass/contact.sass"
import ContactForm from '../components/contactForm'

import phoneIcon from "../assets/icons/phone.svg"
import emailIcon from "../assets/icons/email.svg"

const Contact = () => {
    
    return ( <div className="info-page contact-page">
        <ContactForm/>

        <div className="parent-map-wrapper">
            <div className="map-wrapper">
            </div>
        </div>
        
        <div className="contact-statement contact-statement-desktop">
            <h3>We do it all!</h3>
            <ul>
                <li>Mowing</li>
                <li>Mulching</li>
                <li>Trimming</li>
                <li>Paverss</li>
                <li>Retaining Walls</li>
                <li>Snow Removal</li>
                <li>Spring and Fall Clean Up</li>
                <li>Sodding and Top Soil</li>
            </ul>
        </div>

        <div className="contact-page-info">
            <h3>Give us a call or send us an email</h3>
            <Link className="contact-page-links" href="tel:630-664-3463">
                <img src={phoneIcon} height="20px" alt="Phone Icon"/>
                <p>630-664-3463</p>
            </Link>
            <Link className="contact-page-links" href="mailto:kevinslandscapingco@gmail.com">
                <img src={emailIcon} height="20px" alt="Email Icon"/> 
                <p>kevinslandscapingco@gmail.com</p>
            </Link>
            <p className="accept-cedit-statement">We accept Credit Cards</p>
        </div>

        <div className="contact-statement contact-statement-mobile">
            <h3>We do it all!</h3>
            <ul>
                <li>Mowing</li>
                <li>Mulching</li>
                <li>Trimming</li>
                <li>Retaining Walls</li>
                <li>Snow Removal</li>
                <li>Spring and Fall Clean Up</li>
                <li>Sodding and Top Soil</li>
            </ul>
        </div>

    </div>

    )}

export default Contact